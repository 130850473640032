import React, { useState, useRef } from "react";
import { Formik, Form, Field } from "formik";
import NotificationSystem from "react-notification-system";
import Layout from "../../components/Layout";
import { login, LogUserIn } from "../../api/auth";
import { validateName, validatePassword } from "../../utils/FormValidations";
import FormError from "../../components/FormError";
import { CircularProgress } from "@mui/material";

export default function LoginPage() {
  const notificationSystem = useRef();
  const addNotification = ({ title, message, level }) => {
    const notification = notificationSystem.current;

    notification.addNotification({
      title,
      message,
      level,
    });
  };

  const [authError, setAuthError] = useState(null);

  const [buttonState, setButtonState] = useState(false);

  const signIn = async (values) => {
    setButtonState(true);
    let username = values.username;
    let password = values.password;
    try {
      const response = await LogUserIn(JSON.stringify({ username, password }));
      console.log(response)
      if (response.status === 200) {
        login(response.data.token);
        addNotification({
          title: "Success",
          message: "You have logged in successfully",
          level: "success",
        });
        window.location.pathname = "/dashboard";
      }
      setButtonState(false);
    } catch (err) {
      console.log(err)
      setButtonState(false);
      if (err.response.data.non_field_errors) {
        setAuthError("Username or password invalid");
        addNotification({
          title: "Error",
          message: "Username or password invalid",
          level: "error",
        });
      } else {
        setAuthError("An Unexpected error has occured");
        addNotification({
          title: "Error",
          message: "An Unexpected error has occured",
          level: "error",
        });
      }
    }
  };

  return (
    <Layout>
      <NotificationSystem ref={notificationSystem} />
      <main>
        <div>
          <div className=" ">
            <div className="pt-24 pr-4 pb-24 pl-4">
              <div className="max-w-9xl mt-0 mr-auto mb-0 ml-auto">
                <div className="w-full mt-0 mr-auto border py-8 px-5 border-blue-100 rounded-3xl mb-0 ml-auto space-y-5 sm:w-9/12 md:w-7/12 lg:w-5/12 xl:w-4/12">
                  <p className="font-semibold text-3xl text-blue-500 mb-4 text-center">
                    Login
                  </p>
                  <Formik
                    initialValues={{
                      username: "",
                      password: "",
                    }}
                    onSubmit={(values) => {
                      signIn(values);
                    }}
                  >
                    {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      values,
                      validateOnChange,
                    }) => (
                      <Form className="form">
                        <div className="block mb-4">
                          <p className="text-grey-700 font-medium text-sm mt-6 mr-0 mb-1 ml-0 block">
                            Username
                          </p>
                          <Field
                            type="text"
                            name="username"
                            className="border border-blue-400 w-full rounded-md border
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                            placeholder="Username*"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setAuthError(null);
                            }}
                            validate={validateName}
                          />
                          <div className="text-red-400 text-xs">
                            {errors.username &&
                              touched.username &&
                              errors.username}
                          </div>
                        </div>
                        <div className="block">
                          <p className="text-gray-700 font-medium text-xs mt-4 mr-0 mb-1 ml-0 block">
                            Password
                          </p>
                          <Field
                            type="password"
                            name="password"
                            className="border border-blue-400 w-full rounded-md 
                            hover:border-blue-600 pt-2 pr-3 pb-2 pl-3"
                            placeholder="******"
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleChange(e);
                              setAuthError(null);
                            }}
                            validate={validatePassword}
                          />
                          <FormError
                            error={errors.password}
                            touched={touched.password}
                          />
                        </div>
                        <button
                          type="submit"
                          className="rounded-md mt-4 w-full pt-2.5 pr-3.5 pb-2.5 pl-3.5
                              text-white bg-blue-500 hover:bg-blue-700 cursor-pointer"
                        >
                          {buttonState ? (
                            <CircularProgress className="text-white" size={25} />
                          ) : (
                            "Login"
                          )}
                        </button>
                        <div className="text-red-400 mt-4">
                          {authError ? <p>{authError} </p> : null}
                        </div>
                        <div className="mt-3 mr-0 mb-2 ml-0 text-center">
                          <a
                            href="/"
                            className="text-sm text-gray-700 underline hover:text-purple-700"
                          >
                            Forgot password
                          </a>
                        </div>
                        <div className="text-center w-full">
                          Don&apos;t have an Account?{" "}
                          <span>
                            <a
                              href="sign-up"
                              className="text-sm text-gray-700 underline hover:text-purple-700"
                            >
                              Sign Up
                            </a>
                          </span>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  );
}
